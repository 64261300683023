<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-04 10:57:03
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-23 14:45:00
-->
<template>
  <div class="user-modal">
    <a-modal :title="handle == 'add'? '新增' : '编辑'" v-model="visible" width="75%" :confirm-loading="confirmLoading" :footer="null" :maskClosable="false">
      <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{span:6}" :wrapper-col="{span:18}">
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="头像">
              <QiniuUpload type="image" :value.sync="rowData.headPortrait"></QiniuUpload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <!-- <a-col :md="12">
            <a-form-model-item label="请选择经销商" :autoLink="false" ref="test" prop="dealerId">
              <a-select placeholder="请选择" v-model="rowData.dealerId"   @change="onDealerChange">
                <a-select-option :value="item.id" v-for="(item, index) in dealerList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :md="12">
            <a-form-model-item label="用户名" prop="loginName">
              <a-input v-model="rowData.loginName" placeholder="登陆账号" :disabled="handle == 'edit'"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="姓名" prop="name">
              <a-input v-model="rowData.name"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="性别" prop="sex">
              <a-radio-group v-model="rowData.sex">
                <a-radio :value="1">男</a-radio>
                <a-radio :value="2">女</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>

          <a-col :md="12">
            <a-form-model-item label="所属角色" :autoLink="false" ref="test" prop="roles">
              <a-select placeholder="请选择" v-model="rowData.roles"  @change="onRoleChange" mode="multiple">
                <a-select-option :value="item.id" v-for="(item, index) in roleList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="所属部门" :autoLink="false" ref="test" prop="deptId">
              <!-- <a-tree-select
                @change="onDepaChange"
                v-model="rowData.deptId"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="deptList"
                placeholder="所属部门"
                treeDefaultExpandAll
              >
              </a-tree-select> -->
              <a-tree-select
                    v-model="rowData.deptId"
                    :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                    :treeData="depts"
                    treeDefaultExpandAll
                    @change="handleChangeDept"
                    placeholder="部门"
                    allowClear
                  >
                  </a-tree-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="所属岗位" :autoLink="false" ref="test" prop="stationId">
              <a-select placeholder="请选择" v-model="rowData.stationId"  @change="onStationChange">
                <a-select-option :value="item.id" v-for="(item, index) in stationList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <!-- <a-col :md="12">
            <a-form-model-item label="所属职位" :autoLink="false" ref="test" prop="positionId">
              <a-select placeholder="请选择" v-model="rowData.positionId"  @change="onPositionChange">
                <a-select-option :value="item.id" v-for="(item, index) in positionList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="联系电话" prop="mobile">
              <a-input v-model="rowData.mobile" placeholder="初始密码为手机号后6位"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="邮箱" >
              <a-input v-model="rowData.email" placeholder="邮箱"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="出生年月" :autoLink="false" ref="test">
              <DatePicker :startTime.sync="rowData.birthday"></DatePicker>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="入职日期" :autoLink="false" ref="test">
              <DatePicker :startTime.sync="rowData.joinDate"></DatePicker>
            </a-form-model-item>
          </a-col>

        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="转正日期" :autoLink="false" ref="test">
              <DatePicker :startTime.sync="rowData.formalDate"></DatePicker>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="离职日期" :autoLink="false" ref="test">
              <DatePicker :startTime.sync="rowData.leaveDate"></DatePicker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="在职状态">
              <a-radio-group v-model="rowData.workStatus">
                <a-radio :value="1">试用期</a-radio>
                <a-radio :value="2">已转正</a-radio>
                <a-radio :value="3">已离职</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="状态">
              <a-radio-group v-model="rowData.flagEnable">
                <a-radio :value="true">启用</a-radio>
                <a-radio :value="false">停用</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="学历">
              <a-radio-group v-model="rowData.education">
                <a-radio :value="1">高中及以下</a-radio>
                <a-radio :value="2">大专</a-radio>
                <a-radio :value="3">本科</a-radio>
                <a-radio :value="4">研究生</a-radio>
                <a-radio :value="5">博士</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="居住地址">
              <PCD
                ref="pcd"
                placeholder="请选择区域"
                :province.sync="rowData.province"
                :city.sync="rowData.city"
                :district.sync="rowData.county"
                :provinceId.sync="rowData.provinceId"
                :cityId.sync="rowData.cityId"
                :districtId.sync="rowData.countyId">
              </PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="详细地址">
              <a-input v-model="rowData.address"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="紧急联系人">
              <a-input v-model="rowData.connectorName"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="紧急联系人电话">
              <a-input v-model="rowData.connectorPhone"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="24">
            <a-form-model-item label="备注" :label-col="{span:3}" :wrapper-col="{span:21}">
              <a-textarea v-model="rowData.remark" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="onCancel()">取消</a-button>
        <a-button type="primary" @click="onSubmit()" :loading="loading">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { addFirmManager, editFirmManager, selectByIdFirmManager } from '../api/FirmManagerApi.js'
import moment from 'moment'
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      rowData: {},
      handle: '',
      // 表单验证
      formRule: {
        dealerId: [
          {required: true, message: '请选择经销商', trigger: 'change'}
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        sex: [
          {required: true, message: '请选择性别', trigger: 'change'}
        ],
        loginName: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],

        deptId: [
          {required: true, message: '请选择所属部门', trigger: 'change'}
        ],
        roles: [
          {required: true, message: '请选择所属角色', trigger: 'change'}
        ],
        stationId: [
          {required: true, message: '请选择所属岗位', trigger: 'change'}
        ],
        // positionId: [
        //   {required: true, message: '请选择所属职位', trigger: 'change'}
        // ],
        mobile: [
          {required: true, pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入正确的手机号', trigger: 'blur'}
        ],
        email: [
          {pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/), message: '请输入正确的邮箱地址', trigger: 'blur'}
        ],
        birthday: [
          {required: true, message: '请选择出生年月', trigger: 'change'}
        ],
        joinDate: [
          {required: true, message: '请选择入职日期', trigger: 'change'}
        ],
        formalDate: [
          {required: false, message: '请选择转正日期', trigger: 'change'}
        ],
        workStatus: [
          {required: true, message: '请选择在职状态', trigger: 'change'}
        ],
        education: [
          {required: true, message: '请选择学历', trigger: 'change'}
        ],
      },
      deptList: [],
      roleList: [],
      stationList: [],
      //positionList: [],
      dealerList: [],
      depts:[],
      loading:false
    }
  },
  created() {
    this.getDeptList()
  },
  methods: {
    isShow(row, name) {
      this.visible = true
      this.handle = name
      this.getList()
      this.rowData = {}
      this.deptList = []
      this.rowData.birthday = null
      this.rowData.joinDate = null
      this.rowData.formalDate = null
      this.rowData.leaveDate = null
      if(name == 'add') {
        this.$set(this.rowData, 'flagEnable', true)
        this.$set(this.rowData, 'education', 1)
        this.$set(this.rowData, 'workStatus', 1)
        this.$set(this.rowData, 'sex', 1)
      }
      if(name == 'edit') {
        this.rowData.id = row.id
        selectByIdFirmManager(row.id).then(res => {
          this.rowData = res.body

          //根据部门获取岗位
          if(this.rowData.deptId != null){
            this.getStationListByDept(this.rowData.deptId);
          }

          this.rowData.loginName = res.body.login.loginName
          this.$set(this.rowData, 'provinceId', res.body.provinceId)
          this.$set(this.rowData, 'province', res.body.province)
          this.$set(this.rowData, 'cityId', res.body.cityId)
          this.$set(this.rowData, 'city', res.body.city)
          this.$set(this.rowData, 'countyId', res.body.countyId)
          this.$set(this.rowData, 'county', res.body.county)
          this.$set(this.rowData, 'address', res.body.address)
          this.$nextTick(() => {
            this.$refs.pcd.setPCD()
          })
        })
      }
    },

    getStationListByDept(deptId) {
      this.axios.get('/api/base/system/station/selectStationByDeptId/'+deptId).then(res => {
        this.stationList = res.body
      })
    },

    getList() {
      // 获取经销商数据
      // this.axios.get('/api/dealer/dealer/dealerInfo/listAll').then(res => {
      //   this.dealerList = res.body
      // })
      // 获取部门数据
      this.axios.get('/api/base/system/firm/departmentManagement/all').then(res => {
        this.buildtree(res.body, this.deptList, 0)
      })
      // 获取角色数据
      this.axios.get('/api/base/firm/role/all').then(res => {
        this.roleList = res.body
      })
      // 获取岗位数据
      // this.axios.get('/api/base/system/firm/statioManagement/listFirmAll').then(res => {
      //   this.stationList = res.body
      // })
      // // 获取职位数据
      // this.axios.get('/api/dealer/dealer/dealerPosition/list?pageSize=9999').then(res => {
      //   this.positionList = res.body.records
      // })
    },

      getDeptList() {
      this.axios.get('/api/base/system/firm/departmentManagement/all').then((res) => {
        const data = res.body
        this.buildtree(data, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    handleChangeDept(value, label, extra) {
      //根据部门获取岗位
          if(this.rowData.deptId){
            this.$set(this.rowData,'stationId','')
            this.getStationListByDept(this.rowData.deptId);
          }
    },

    onCancel() {
      this.$refs.form.resetFields()
      this.visible = false
      this.loading=false
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.loading=true
        let url = this.handle == 'edit' ? editFirmManager : addFirmManager
        url(this.rowData).then(res => {
          if(res.code == 200 ) {
            this.$message.success(res.message)
            this.visible = false
            this.loading=false
            this.$emit('ok')
          }
        }).finally(()=>{
          this.loading=false
        })
      })
    },
    onDepaChange() {

      // 获取岗位数据
      this.axios.get(`/api/base/system/firm/statioManagement/listFirmAll?deptId=${this.rowData.deptId}`).then(res => {
        this.stationList = res.body
      })

      this.$refs.test.onFieldChange()
    },
    onRoleChange(value) {
      this.$refs.test.onFieldChange()
    },
    onStationChange(val){
      this.$refs.test.onFieldChange()
    },
    onPositionChange(val){
      this.$refs.test.onFieldChange()
    },
    onDealerChange(val) {
      this.$refs.test.onFieldChange()
    }
  }
}
</script>

<style lang="scss">
.footer-bts {
  text-align: right;
}
</style>
