<template>
  <a-card :bordered="false">
    <a-row :gutter="8">
      <!-- <a-col :span="5">
        <search-tree :treeData="deptTree" :dataList="dataList" :expandedKeys="expandedKeys" :holderText="holderText"
          @select="handleSelect">
        </search-tree>
      </a-col> -->
      <a-col :span="24">
        <div class="table-page-search-wrapper">
          <a-form layout="inline" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
            <a-row :gutter="8">
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="用户名">
                  <a-input placeholder="请输入" v-model="queryParam.name" allowClear />
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="部门">
                  <!-- <a-select placeholder="所属部门" v-model="queryParam.deptId">
                    <a-select-option :value="item.id" v-for="(item, index) in deptList" :key="index">{{ item.name }}
                    </a-select-option>
                  </a-select> -->
                  <a-tree-select
                    v-model="queryParam.deptId"
                    :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                    :treeData="depts"
                    treeDefaultExpandAll
                    @change="handleChangeDept"
                    placeholder="部门"
                    allowClear
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="状态">
                  <a-select placeholder="请选择" v-model="queryParam.workStatus" allowClear>
                    <a-select-option :value="''">全部</a-select-option>
                    <a-select-option :value="0">关闭</a-select-option>
                    <a-select-option :value="1">启用</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button type="primary" @click="getList(0)">查询</a-button>
                  <a-button style="margin-left: 8px" @click="getList(1)">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-col>
    </a-row>
    <div class="table-operator">
      <a-button v-if="addEnable" type="primary" icon="plus" @click="onAdd()">新建</a-button>
      <a-dropdown v-if="removeEnable && selectedRowKeys.length > 0">
        <a-button type="danger" icon="delete" @click="delByIds(selectedRowKeys)">删除</a-button>
      </a-dropdown>
    </div>
    <a-table
      size="default"
      ref="table"
      rowKey="id"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :columns="columns"
      :data-source="tableList"
      :pagination="page"
      @change="changeTable"
    >
      <span slot="roles" slot-scope="text">
        <span v-for="(item, index) in text" :key="index">{{ item.name }}</span>
      </span>
      <span slot="flagEnable" slot-scope="text, record">
        <a-switch v-model="record.flagEnable" @change="onChangeStatus(record)" />
      </span>
      <span slot="workStatus" slot-scope="text">
        <a-badge status="warning" v-if="text == 1" text="试用期" />
        <a-badge status="success" v-if="text == 2" text="已转正" />
        <a-badge status="error" v-if="text == 3" text="已离职" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a v-if="editEnabel" @click="handleEdit(record)">编辑</a>
        <a-divider type="vertical" />
        <a v-if="relationDealerEnable" @click="handleRelation(record)">关联经销商</a>
        <!--&& !record.flagSystem-->
        <a-divider type="vertical" />
        <a v-if="removeEnable" @click="delByIds([record.id])">删除</a>
        <a-divider type="vertical" />
        <a v-if="resetPwdEnabel" @click="resetPwd(record)">重置密码</a>
      </span>
    </a-table>
    <user-modal ref="modal" @ok="getList(1)" :deptTree="deptTree" :cities="cities" />
    <user-pwd-modal ref="pwdmodal" @reset="getList()"/>
    <link-dealer ref="linkDealer" @handleDealer="handleDealer"></link-dealer>
  </a-card>
</template>

<script>
import { STable, SearchTree } from '@/components'
import UserModal from './components/FirmManagerEditModal.vue'
import UserPwdModal from './components/UserPwdModal.vue'
import linkDealer from './components/linkDealer.vue'
import { listFirmManager, delFirmManager } from './api/FirmManagerApi.js'
import { checkPermission } from '@/utils/permissions'

export default {
  name: 'UserList',
  components: {
    SearchTree,
    STable,
    UserModal,
    UserPwdModal,
    linkDealer,
  },
  data() {
    return {
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        sourceType: 1,
      },
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '登录名',
          dataIndex: 'loginName',
        },
        {
          title: '昵称',
          dataIndex: 'name',
        },
        {
          title: '所属部门',
          dataIndex: 'deptName',
        },
        {
          title: '所属角色',
          dataIndex: 'roleName',
        },
        {
          title: '在职状态',
          dataIndex: 'workStatus',
          scopedSlots: { customRender: 'workStatus' },
        },
        {
          title: '是否启用',
          dataIndex: 'flagEnable',
          scopedSlots: { customRender: 'flagEnable' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true,
        },
        {
          title: '操作',
          width: '260px',
          dataIndex: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      tableList: [],
      deptTree: [],
      holderText: '搜索部门',
      selectedRowKeys: [],
      selectedRows: [],
      cities: [],
      addEnable: checkPermission('firm:manager:add'),
      editEnabel: checkPermission('firm:manager:edit'),
      resetPwdEnabel: checkPermission('firm:manager:resetPwd'),
      removeEnable: checkPermission('firm:manager:remove'),
      relationDealerEnable: checkPermission('firm:manager:relation_dealer'),
      expandedKeys: [0],
      dataList: [],
      StationId: 0,
      visible: false,
      confirmLoading: false,
      deptList:[],
      depts:[]
    }
  },
  filters: {
    typeFilter(type) {
      const typeMap = {
        1: '试用期',
        2: '已转正',
        3: '已离职',
      }
      return typeMap[type]
    },
  },
  created() {
    this.getList()
    this.getDeptList()
    //this.getTreeList()
  },
  methods: {
    // 关联经销商
    handleRelation(row) {
      this.$refs.linkDealer.isDealer(row)
    },
    handleDealer(){
      this.getList()
    },
    // 分页
    changeTable(pagination) {
      this.page = pagination
      this.getList()
    },
    getList(num) {
      if(num == 1) {
        this.queryParam = {}
        this.page.current = 1
        this.page.pageSize = 10
      } else if(num == 0){
        this.page.current = 1
      }
      const obj={
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
      }
      let params = Object.assign({}, obj, this.queryParam)
      listFirmManager(params).then((res) => {
        this.tableList = res.body.records
        this.page.total = res.body.total
      })

    },
    // getDeptList() {
    //   this.axios.get('/api/base/system/firm/departmentManagement/all').then(res => {
    //     this.deptList = res.body
    //   })
    // },
       getDeptList() {
      this.axios.get('/api/base/system/firm/departmentManagement/all').then((res) => {
        const data = res.body
        this.buildtree(data, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    handleChangeDept(value, label, extra) {
      // if (extra.selected && extra.triggerNode.$children.length > 0) {
      //   this.$message.error('不能选择父节点 ' + extra.triggerNode.title)
      //   value.splice(
      //     value.findIndex((item) => item === extra.triggerValue),
      //     1
      //   )
      // }
    },

    // 获取左侧树
    // getTreeList() {
    //   this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
    //     this.dataList = res.body
    //   })
    // },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 选中筛选
    onhandleSelect(value) {
      let id = Number(value.toString())
      this.StationId = id
      this.getList()
    },

    onAdd() {
      this.$refs.modal.isShow(null, 'add')
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleEdit(record) {
      this.$refs.modal.isShow(record, 'edit')
    },
    resetPwd(record) {
      this.$refs.pwdmodal.edit(record)
    },
    onChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleOk() {
      this.$refs.table.refresh()
    },
    delByIds(ids) {
      const that = this
      this.$confirm({
        title: '警告',
        content: `真的要删除选中用户吗?`,
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          // 在这里调用删除接口
          delFirmManager(ids).then((res) => {
            if (res.code === 200) {
              that.getList(1)
              that.$message.success(res.message)
            } else {
              that.$message.error(res.message)
            }
            // const difference = new Set(this.selectedRowKeys.filter(x => !new Set(ids).has(x)))
            // this.selectedRowKeys = Array.from(difference)
            that.selectedRowKeys = []
          })
        },
        onCancel() {
          // console.log('Cancel')
        },
      })
    },
    onChangeStatus(row) {
      let status = row.flagEnable
      this.axios.get(`/api/base/system/firm/manager/status/isEnable?userId=${row.id}&flagEnable=${status}`).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.getList()
        } else {
          this.getList()
        }
      }).catch(err => {
        this.getList()
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: {
              title: 'title',
            },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    // 下面是树相关方法
    handleSelect(selectedKeys, info) {
      this.queryParam = {
        departmentId: selectedKeys[0],
      }
      this.$refs.table.refresh(true)
    },
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  },
}
</script>

<style lang="less" scoped>
.tree-outer {
  height: 500px;
  overflow-y: auto;
}
</style>
