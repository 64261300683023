<template>
  <a-modal
    title="关联经销商"
    :visible="visible"
    width="40%"
    :confirm-loading="confirmLoading"
    @ok="handleOkDealer"
    @cancel="handleCancelDealer"
    :maskClosable="false"
  >
    <a-table
      size="default"
      :row-selection="rowSelection"
      :columns="columns"
       bordered
      :scroll="{ y: 300 }"
      :data-source="tableList"
      :pagination="false"
      :rowKey="(record) => record.id"
    >
    </a-table>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      rowId:'',
      idChoose:'',
      rowName:'',
      visible: false,
      confirmLoading: false,
      tableList:[],
      selectedRowKeys:[],
      selectedRows:[],
      columns: [
        {
          title: '经销商',
          key:'name',
          align:'center',
          dataIndex: 'name',
        },
      ],
    }
  },
  computed: {
    rowSelection() {
      // const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        hideDefaultSelections: true,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = Array.from(new Set(selectedRowKeys))
          this.selectedRows = selectedRows
  
        },
         getCheckboxProps:(record) =>{
           return{
            props:{
              defaultChecked:this.selectedRowKeys.includes(record.id), 
            }
           }
          }
      }
    },
  },
  methods: {
    isDealer(row) {
      this.visible = true
      let obj ={
        managerId:row.id
      }
      console.log(row.id)
      this.rowId = row.id
      this.rowName = row.name
      this.getDataList(obj)
    },
    getDataList(obj){
      this.selectedRowKeys = []
      this.selectedRows = []
      this.axios.get('/api/dealer/dealer/dealerInfo/listDealerForManager',{params:obj}).then(res => {
      let tableListArr = Object.values(res.body)
      // console.log(tableListArr[1].length)
      if(tableListArr[1].length == 0){
        this.tableList = tableListArr[0]
      }else if(tableListArr[0].length == 0){
        this.tableList = tableListArr[1]
      }else if(tableListArr[0].length != 0 && tableListArr[1].length != 0){
        this.tableList = tableListArr[1].concat(tableListArr[0])
      }else if(tableListArr[0].length == 0 && tableListArr[1].length == 0){
         this.tableList = tableListArr[1].concat(tableListArr[0])
      }
      
       let ids = tableListArr[1].map(item => {
        return item.id
      })  
      this.selectedRowKeys = ids  
       
  })
    },

    handleOkDealer() { 
      let obj ={
        managerId:this.rowId
      }
      console.log(this.selectedRowKeys)
      let params={
        dealerIds:this.selectedRowKeys,
        managerId:this.rowId,
        managerName:this.rowName
      }
      this.axios.post('/api/dealer/dealer/dealerInfo/createDealerRelation',params).then(res=>{
       if(res.code == 200){
         this.$message.success(res.message)
         this.visible = false
         this.getDataList(obj)
         this.$emit('handleDealer')
       }
      })
    },
    handleCancelDealer() {
      this.visible = false
      this.selectedRowKeys = []
      console.log(this.selectedRowKeys)
      this.selectedRows = []
      this.tableList=[]
      let obj ={
        managerId:this.rowId
      }
      console.log(obj)
      this.getDataList(obj)
      this.$emit('handleDealer')
    },
  },
}
</script>
<style lang="less" scoped>
</style>